import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import Styles from "./style.module.scss";
import { Link, useNavigate } from "react-router-dom";
import Button from "../Button";

// Images
import Logo from "../../Assets/Images/logo.png";
import Facebook from "../../Assets/Images/facebook2.png";
import Gmail from "../../Assets/Images/gmail2.png";
import Linkedln from "../../Assets/Images/linkedln2.png";
import Instagram from "../../Assets/Images/instagram.png";
import Twitter from "../../Assets/Images/twitter.png";

const Footer = () => {
  const navigate = useNavigate();

  const navToGuard = () => {
    navigate("/service-security-guard");
  };
  const navToRemote = () => {
    navigate("/service-remote-video");
  };
  const navToAviation = () => {
    navigate("/service-aviation-security");
  };
  const navToElectronic = () => {
    navigate("/service-electronic-security");
  };
  const navToFire = () => {
    navigate("/service-fire-safety");
  };
  return (
    <div className={Styles.Container}>
      <Container>
        <div className={Styles.footer_top}>
          <a href="#menu-top">Back on top</a>
        </div>
        <div className={Styles.footer_middle}>
          <Row className="align-items-center">
            <Col md="2">
              <Link to="/">
                <img className={Styles.logo} src={Logo} alt="Footer Logo" />
              </Link>
              <p className={Styles.slogan}>
                Your Business Deserve Better Security
              </p>
            </Col>
            <Col md="3">
              <ul className={Styles.footer_links}>
                <li>
                  <span onClick={navToGuard}>Security Guarding</span>
                </li>
                <li>
                  <span onClick={navToRemote}>Remote Video Solution</span>
                </li>
                <li>
                  <span onClick={navToAviation}>Aviation Security</span>
                </li>
                <li>
                  <span onClick={navToElectronic}>Electronic Security</span>
                </li>
                <li>
                  <span onClick={navToFire}>Fire & Safety</span>
                </li>
              </ul>
            </Col>
            <Col md="3">
              <ul className={Styles.footer_links}>
                <li>
                  <Link to="/security">Security Camera Trailer</Link>
                </li>
                <li>
                  <Link to="/about">About us</Link>
                </li>
                <li>
                  <Link to="/contact">Contact us</Link>
                </li>
                <li>
                  <Link to="/careers">Careers</Link>
                </li>
              </ul>
            </Col>
            <Col md="3">
              <Button link="/consultancy" title="Free Consultancy" />
            </Col>
          </Row>
        </div>
        <div className={Styles.footer_bottom}>
          <div className={Styles.footerSocial}>
            <Link to="/">
              <img src={Facebook} alt="Facebook" />
            </Link>
            <Link to="/">
              <img src={Gmail} className={Styles.socailIco2} alt="Gmail" />
            </Link>
            <Link to="/">
              <img
                src={Linkedln}
                className={Styles.socailIco2}
                alt="Linkedln"
              />
            </Link>
            <Link to="/">
              <img src={Instagram} className={Styles.socailIco2} alt="Insta" />
            </Link>
            <Link to="/">
              <img src={Twitter} className={Styles.socailIco2} alt="Twitter" />
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
