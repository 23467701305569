import React, { Fragment, useEffect } from "react";
import Header from "../../Components/Header";
import HeaderWithText from "../../Components/HeaderWithText";
import ContactForm from "./contactForm";
import Footer from "../../Components/Footer";

const ContactUs = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  return (
    <Fragment>
      <Header />
      <HeaderWithText
        breadCrumb1="Home"
        breadCrumb2="Contact Us"
        title="Contact Us"
      />
      <ContactForm />
      <Footer />
    </Fragment>
  );
};

export default ContactUs;
