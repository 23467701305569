import React from "react";
import { Container } from "react-bootstrap";
import Styles from "../serviceInner.module.scss";

import Tick from "../../../Assets/Images/tic-green.png";
import Form from "../../../Components/Form";

const Section1 = () => {
  return (
    <Container className={Styles.section1security}>
      <h1 className={Styles.heading}>Remote Video Solution</h1>
      <p className={Styles.description}>
        Achieve tranquility with our remote video solutions, offering continuous
        surveillance and live monitoring for enhanced security no matter where
        you are. With cutting-edge technology and a proficient monitoring team,
        we guarantee swift action in response to any unusual activity,
        delivering total safeguarding for your assets and premises.
      </p>
      <div className={Styles.listOptions}>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            24/7 Surveillance: Provide uninterrupted monitoring of your premises
            with round-the-clock video surveillance.Real-Time Monitoring:
            Utilize advanced technology to enable real-time monitoring and
            immediate response to any suspicious activity.
          </p>
        </div>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            Expert Monitoring Team: Employ a team of skilled professionals to
            oversee the video feeds and take prompt action when needed.
          </p>
        </div>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            Customized Solutions: Tailor the remote video solution to meet the
            specific security needs and requirements of each client.
          </p>
        </div>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            Integration: Integrate remote video solutions with other security
            systems for comprehensive protection and seamless operation.
          </p>
        </div>
      </div>
      <div className={Styles.securityForm}>
        <h1 className={Styles.heading}>Contact Our Team</h1>
        <Form />
      </div>
    </Container>
  );
};

export default Section1;
