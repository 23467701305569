import React from "react";
import { Container } from "react-bootstrap";
import Styles from "../serviceInner.module.scss";

import Tick from "../../../Assets/Images/tic-green.png";
import Form from "../../../Components/Form";

const Section1 = () => {
  return (
    <Container className={Styles.section1security}>
      <h1 className={Styles.heading}>Aviation Security</h1>
      <p className={Styles.description}>
        Rely on our expertise in aviation security to protect airports and
        aviation facilities with advanced measures and specialized knowledge.
        Our trained professionals comprehend the distinct challenges of the
        aviation sector, offering customized security solutions to safeguard
        passengers, personnel, and infrastructure.
      </p>
      <div className={Styles.listOptions}>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            Through Screening: Implement rigorous screening procedures for
            passengers, luggage, and cargo to mitigate potential security
            threats.
          </p>
        </div>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            Perimeter Protection: Establish robust perimeter security measures
            to prevent unauthorized access to sensitive areas of airports and
            aviation facilities.
          </p>
        </div>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            Emergency Response Planning: Develop comprehensive emergency
            response plans and conduct regular drills to ensure swift and
            effective responses to security incidents or emergencies.
          </p>
        </div>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            Technology Integration: Utilize cutting-edge security technology
            such as advanced surveillance systems, biometric authentication, and
            explosive detection devices to enhance security measures.
            Collaboration with Authorities: Foster strong partnerships with
            local law enforcement agencies and aviation authorities to share
            intelligence and coordinate security efforts effectively.
          </p>
        </div>
      </div>
      <div className={Styles.securityForm}>
        <h1 className={Styles.heading}>Contact Our Team</h1>
        <Form />
      </div>
    </Container>
  );
};

export default Section1;
