import React from "react";
import { Container, Breadcrumb } from "react-bootstrap";
import Styles from "./style.module.scss";

const HeaderWithText = ({ breadCrumb1, breadCrumb2, title }) => {
  return (
    <Container className={Styles.headerWithText}>
      <Breadcrumb className={Styles.breadCrumbBox}>
        <Breadcrumb.Item className={Styles.breadCrumb} active>
          {breadCrumb1}
        </Breadcrumb.Item>
        <Breadcrumb.Item className={Styles.breadCrumb} active>
          {breadCrumb2}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className={Styles.mainTitle}>
        <h1 className={Styles.mainHeading}>{title}</h1>
      </div>
    </Container>
  );
};

export default HeaderWithText;
