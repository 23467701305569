import React from "react";
import { Container } from "react-bootstrap";
import Styles from "../serviceInner.module.scss";

import Tick from "../../../Assets/Images/tic-green.png";
import Form from "../../../Components/Form";

const Section1 = () => {
  return (
    <Container className={Styles.section1security}>
      <h1 className={Styles.heading}>Fire And Safety</h1>
      <p className={Styles.description}>
        Ensure your readiness and safety with our all-encompassing fire and
        safety services, dedicated to minimizing risks and safeguarding your
        surroundings. Our skilled team performs detailed assessments and devises
        tailored solutions to tackle fire hazards and foster a secure workplace
        for both employees and visitors.
      </p>
      <div className={Styles.listOptions}>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            Risk Assessment: Conduct thorough assessments to identify fire
            hazards and potential risks.
          </p>
        </div>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            Customized Solutions: Develop personalized fire safety plans and
            protocols tailored to each client's specific needs.
          </p>
        </div>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            Regular Maintenance: Conduct regular maintenance and updates of
            electronic security equipment to ensure reliability and
            effectiveness in protecting assets and facilities.
          </p>
        </div>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            Employee Training: Provide comprehensive training to staff on fire
            safety procedures and emergency response protocols.
          </p>
        </div>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            Regular Inspections: Perform routine inspections and maintenance of
            fire safety equipment to ensure functionality and compliance.
            Emergency Preparedness: Foster a culture of readiness and awareness
            to effectively respond to fire emergencies
          </p>
        </div>
      </div>
      <div className={Styles.securityForm}>
        <h1 className={Styles.heading}>Contact Our Team</h1>
        <Form />
      </div>
    </Container>
  );
};

export default Section1;
