import React, { useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import Styles from "./style.module.scss";
import TextInput from "../../Components/Inputs/TextInput";
import TextAreaInput from "../../Components/Inputs/TextareaInput";
import Button from "../../Components/Button";
import emailjs from "@emailjs/browser";
import AlertBox from "../../Components/Alert";
import Loader from "../Loader";

const DataForm = () => {
  const form = useRef();

  const initialValues = {
    fname: "",
    lname: "",
    email: "",
    phone: "",
    additional: "",
  };

  const [formData, setFormData] = useState(initialValues);
  const [isLoader, setIsLoader] = useState(false);
  const [isApiResult, setIsApiResult] = useState({
    show: false,
    type: 1,
    message: "",
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setIsLoader(true);

    if (
      formData.fname !== "" &&
      formData.lname !== "" &&
      formData.email !== "" &&
      formData.phone !== ""
    ) {
      emailjs
        .sendForm(
          "service_h43q1i5",
          "template_ohsqsq8",
          form.current,
          "dHLCEzRKfc5Pd_JI-"
        )
        .then((response) => {
          setIsLoader(false);
          setIsApiResult({
            show: true,
            type: 1,
            message: "Email sent successfully. We will contact you shortly.",
          });

          formData.fname = "";
          formData.lname = "";
          formData.email = "";
          formData.phone = "";
          formData.additional = "";
        })
        .catch((error) => {
          setIsLoader(false);
          setIsApiResult({
            show: true,
            type: 2,
            message: "Something went wrong. Try again later.",
          });
        });
    } else {
      setIsLoader(false);
      setIsApiResult({
        show: true,
        type: 2,
        message: "Please fill required fields.",
      });
    }
  };

  return (
    <div className={Styles.formBox}>
      <form ref={form}>
        <Row>
          <Col md="6">
            <TextInput
              id={formData.fname}
              type="text"
              name="fname"
              value={formData.fname}
              required={true}
              placeholder="First Name"
              onChange={handleOnChange}
            />
          </Col>
          <Col md="6">
            <TextInput
              id={formData.lname}
              type="text"
              name="lname"
              value={formData.lname}
              required={true}
              placeholder="Last Name"
              onChange={handleOnChange}
            />
          </Col>
        </Row>
        <TextInput
          id={formData.email}
          type="email"
          name="email"
          value={formData.email}
          required={true}
          placeholder="Email"
          onChange={handleOnChange}
        />
        <TextInput
          id={formData.phone}
          type="text"
          name="phone"
          value={formData.phone}
          required={true}
          placeholder="Phone"
          onChange={handleOnChange}
        />
        <TextAreaInput
          id={formData.additional}
          name="additional"
          value={formData.additional}
          placeholder="Any additional services needed"
          required={false}
          onChange={handleOnChange}
        />
        <div className={Styles.formButton}>
          <Button onClick={handleOnSubmit} title="Submit" />
        </div>
      </form>
      <br />
      <Loader show={isLoader} />
      <AlertBox
        isShow={isApiResult.show}
        type={isApiResult.type}
        message={isApiResult.message}
      />
    </div>
  );
};

export default DataForm;
