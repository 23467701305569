import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Styles from "./style.module.scss";

import Service1 from "../../Assets/Images/service-img1.jpg";

const Section1 = () => {
  return (
    <Container className={Styles.section1}>
      <Row>
        <Col md="7">
          <div className={Styles.left}>
            <p className={Styles.heading}>What We Offer</p>
            <p className={Styles.description}>
              At Homeland Protect, we offer comprehensive security solutions
              tailored to your needs. From on-site security and mobile patrols
              to cutting-edge technology and corporate risk management, our
              services enclose a wide range of protective measures. Whether you
              require physical guarding or advanced technological solutions,
              we're here to safeguard your assets and provide you with ultimate
              peace of mind.
            </p>
          </div>
        </Col>
        <Col md="5">
          <div className={Styles.right}>
            <img src={Service1} alt="Service 1" className={Styles} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Section1;
