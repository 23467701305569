import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Styles from "./style.module.scss";
import Button from "../../Components/Button";
import getWindowDimensions from "../../Utils/screenSize";

import Service1 from "../../Assets/Images/service-img2.png";
import Service2 from "../../Assets/Images/service-img3.png";
import Service3 from "../../Assets/Images/service-img4.png";
import Service4 from "../../Assets/Images/service-img5.png";
import Service5 from "../../Assets/Images/service-img6.png";

const services = [
  {
    type: "1",
    id: "securityguarding",
    image: Service1,
    heading: "Security Guarding",
    description:
      "Count on our vigilant security guards to stand watch, ensuring your safety with unwavering dedication and expertise. Our trained professionals are equipped to handle any situation, providing a visible impediment to potential threats and offering proactive protection for your peace of mind.",
    link: "/service-security-guard",
  },
  {
    type: "2",
    id: "remotevideo",
    image: Service2,
    heading: "Remote Video Solution",
    description:
      "Gain peace of mind with our remote video solutions, providing round-the-clock surveillance and real-time monitoring to keep your premises secure from anywhere. Our advanced technology and expert monitoring team ensure swift response to any suspicious activity, offering complete protection for your property and assets.",
    link: "/service-remote-video",
  },
  {
    type: "1",
    id: "aviation",
    image: Service3,
    heading: "Aviation Security",
    description:
      "Trust us to provide top-tier aviation security, safeguarding airports and aviation facilities with specialized expertise and advanced security measures. Our trained professionals understand the unique challenges of the aviation industry, delivering tailored security solutions to ensure the safety of passengers, staff, and infrastructure.",
    link: "/service-aviation-security",
  },
  {
    type: "2",
    id: "electronic",
    image: Service4,
    heading: "Electronic Security",
    description:
      "Enhance your security infrastructure with our electronic security solutions, leveraging cutting-edge technology to protect your assets and facilities effectively. From access control systems to CCTV surveillance, our integrated solutions provide comprehensive protection against unauthorized access and security breaches.",
    link: "/service-electronic-security",
  },
  {
    type: "1",
    id: "firesafety",
    image: Service5,
    heading: "Fire and safety",
    description:
      "Stay prepared and protected with our comprehensive fire and safety services, ensuring adherence and eagerness to mitigate risks and ensure the safety of your environment. Our expert team conducts thorough assessments and develops customized solutions to address fire hazards and promote a safe working environment for your employees and visitors.",
    link: "/service-fire-safety",
  },
];

const Section2 = () => {
  const { width } = getWindowDimensions();

  useEffect(() => {
    const scrollSection = (desktop, mobile) => {
      if (width > 1024) {
        setTimeout(() => {
          return window.scrollTo(0, desktop);
        }, 100);
      } else {
        setTimeout(() => {
          return window.scrollTo(0, mobile);
        }, 100);
      }
    };
    if (window.location.hash === "#/services/#securityguarding") {
      scrollSection(1400, 1650);
    } else if (window.location.hash === "#/services/#remotevideo") {
      scrollSection(1800, 2350);
    } else if (window.location.hash === "#/services/#aviation") {
      scrollSection(2200, 3050);
    } else if (window.location.hash === "#/services/#electronic") {
      scrollSection(2700, 3800);
    } else if (window.location.hash === "#/services/#firesafety") {
      scrollSection(3000, 4550);
    }
  }, [width]);

  return (
    <Container className={Styles.section2}>
      {services.length &&
        services.map((data, _idx) =>
          data.type === "1" ? (
            <Row className={Styles.serviceDetailBox} id={data.id}>
              <Col md="4">
                <img
                  id={data.id}
                  className={Styles.detailImage}
                  src={data.image}
                  alt={data.heading}
                />
              </Col>
              <Col md="8">
                <p className={Styles.heading}>{data.heading}</p>
                <p className={Styles.description}>{data.description}</p>
                <Button link={data.link} title="Request Service" />
              </Col>
            </Row>
          ) : (
            <Row className={Styles.serviceDetailBox}>
              <Col md="8">
                <p className={Styles.heading}>{data.heading}</p>
                <p className={Styles.description}>{data.description}</p>
                <Button link={data.link} title="Request Service" />
              </Col>
              <Col md="4">
                <img
                  className={Styles.detailImage}
                  src={data.image}
                  alt={data.heading}
                />
              </Col>
            </Row>
          )
        )}
    </Container>
  );
};

export default Section2;
