import React from "react";
import Styles from "./style.module.scss";

const TextInput = ({
  id,
  label,
  type,
  name,
  value,
  required,
  placeholder,
  onChange,
}) => {
  return (
    <>
      {label && (
        <label className={Styles.inputBoxLabel}>
          {label} <span>{required && "*"}</span>
        </label>
      )}
      <div style={label ? { marginTop: "0" } : { marginTop: "30px" }}>
        <input
          id={id}
          className={Styles.inputBox}
          type={type ? type : "text"}
          name={name}
          value={value}
          required={required ? required : false}
          placeholder={`${placeholder} ${required ? "*" : ""}`}
          onChange={onChange}
        />
      </div>
    </>
  );
};

export default TextInput;
