import React from "react";
import Styles from "./style.module.scss";
import { Link } from "react-router-dom";

const Button = (props) => {
  const { link, title, onClick, purple, state } = props;
  return link ? (
    <Link to={link} className={Styles.linkBtn} state={state ? state : {}}>
      {title}
    </Link>
  ) : (
    <div
      className={purple ? Styles.normalBtnPurple : Styles.normalBtnYellow}
      onClick={onClick}
    >
      {title}
    </div>
  );
};

export default Button;
