import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import TextInput from "../../Components/Inputs/TextInput";
import TextAreaInput from "../../Components/Inputs/TextareaInput";
import RadioInput from "../../Components/Inputs/RadioInput";
import Styles from "./style.module.scss";
import Button from "../../Components/Button";

const RequestForm = () => {
  const initialValues = {
    fname: "",
    lname: "",
    business: "",
    phone: "",
    email: "",
    security: "",
    shift: "",
    day: "",
    hours: "",
    additional: "",
  };

  const [formData, setFormData] = useState(initialValues);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleOnSubmit = () => {};

  return (
    <Container className={Styles.requestFormBox}>
      <Row>
        <Col md="6">
          <TextInput
            id={formData.fname}
            name="fname"
            value={formData.fname}
            required={true}
            placeholder={"First Name"}
            onChange={handleOnChange}
          />
        </Col>
        <Col md="6">
          <TextInput
            id={formData.lname}
            name="lname"
            value={formData.lname}
            required={true}
            placeholder={"Last Name"}
            onChange={handleOnChange}
          />
        </Col>
      </Row>
      <TextInput
        id={formData.business}
        name="business"
        value={formData.business}
        required={true}
        placeholder={"Business"}
        onChange={handleOnChange}
      />
      <TextInput
        id={formData.phone}
        name="phone"
        value={formData.phone}
        required={true}
        placeholder={"Phone"}
        onChange={handleOnChange}
      />
      <TextInput
        id={formData.email}
        name="email"
        type="email"
        value={formData.email}
        required={true}
        placeholder={"Email"}
        onChange={handleOnChange}
      />
      <p className={Styles.heading}>
        Type of security needed <span>*</span>
      </p>
      <Row>
        <Col md="4">
          <RadioInput
            id={formData.security}
            name="security"
            value="Armed Security officer"
            required={true}
            onChange={handleOnChange}
            label="Armed Security officer"
          />
        </Col>
        <Col md="4">
          <RadioInput
            id={formData.security}
            name="security"
            value="unarmed Security officer"
            required={false}
            onChange={handleOnChange}
            label="unarmed Security officer"
          />
        </Col>
      </Row>
      <p className={Styles.heading}>
        Shift Needed <span>*</span>
      </p>
      <Row>
        <Col md="4">
          <RadioInput
            id={formData.shift}
            name="shift"
            value="Morning"
            required={false}
            onChange={handleOnChange}
            label="Morning"
          />
        </Col>
        <Col md="4">
          <RadioInput
            id={formData.shift}
            name="shift"
            value="Evening"
            required={false}
            onChange={handleOnChange}
            label="Evening"
          />
        </Col>
        <Col md="4">
          <RadioInput
            id={formData.shift}
            name="shift"
            value="Overnight"
            required={false}
            onChange={handleOnChange}
            label="Overnight"
          />
        </Col>
      </Row>
      <p className={Styles.heading}>
        Days Requested <span>*</span>
      </p>
      <Row>
        <Col md="4">
          <RadioInput
            id={formData.hours}
            name="day"
            value="Monday"
            required={false}
            onChange={handleOnChange}
            label="Monday"
          />
        </Col>
        <Col md="4">
          <RadioInput
            id={formData.hours}
            name="day"
            value="Tuesday"
            required={false}
            onChange={handleOnChange}
            label="Tuesday"
          />
        </Col>
        <Col md="4">
          <RadioInput
            id={formData.hours}
            name="day"
            value="Wednesday"
            required={false}
            onChange={handleOnChange}
            label="Wednesday"
          />
        </Col>
        <Col md="4">
          <RadioInput
            id={formData.hours}
            name="day"
            value="Thursday"
            required={false}
            onChange={handleOnChange}
            label="Thursday"
          />
        </Col>
        <Col md="4">
          <RadioInput
            id={formData.hours}
            name="day"
            value="Friday"
            required={false}
            onChange={handleOnChange}
            label="Friday"
          />
        </Col>
        <Col md="4">
          <RadioInput
            id={formData.hours}
            name="day"
            value="Saturday"
            required={false}
            onChange={handleOnChange}
            label="Saturday"
          />
        </Col>
        <Col md="4">
          <RadioInput
            id={formData.hours}
            name="day"
            value="Sunday"
            required={false}
            onChange={handleOnChange}
            label="Sunday"
          />
        </Col>
      </Row>
      <TextInput
        id={formData.hours}
        name="hours"
        value={formData.hours}
        required={false}
        placeholder={"Hour needed per week"}
        onChange={handleOnChange}
      />
      <TextAreaInput
        id={formData.additional}
        name="additional"
        value={formData.additional}
        placeholder="Any additional services needed"
        onChange={handleOnChange}
      />
      <div className={Styles.formBtn}>
        <Button onClick={handleOnSubmit} title="Submit" />
      </div>
    </Container>
  );
};

export default RequestForm;
