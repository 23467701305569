import React, { Fragment } from "react";
import Header from "../../Components/Header";
import HeaderWithImage from "../../Components/HeaderWithImage";
import CareerForm from "./careerForm";
import Footer from "../../Components/Footer";

import ConsultancyBanner from "../../Assets/Images/consultancy-banner.jpg";
import MobileBanner from "../../Assets/mobileImages/freeconsult-banner.jpg";

const CareersPage = () => {
  return (
    <Fragment>
      <Header />
      <HeaderWithImage
        breadCrumb1="Home"
        breadCrumb2="Careers"
        title="Apply For an open Position - Employment Application"
        desktopBanner={ConsultancyBanner}
        mobileBanner={MobileBanner}
      />
      <CareerForm />
      <Footer />
    </Fragment>
  );
};

export default CareersPage;
