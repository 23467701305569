import React, { useState, useRef, useEffect } from "react";
import Styles from "./style.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import DropDownInput from "../../Components/Inputs/DropDown";
import TextInput from "../../Components/Inputs/TextInput";
import TextAreaInput from "../../Components/Inputs/TextareaInput";
import Button from "../../Components/Button";
import RadioInput from "../../Components/Inputs/RadioInput";
import emailjs from "@emailjs/browser";
import AlertBox from "../../Components/Alert";
import Loader from "../../Components/Loader";

const CareerForm = () => {
  const form = useRef();

  const initialValues = {
    positionApply: "",
    positionType: "",
    fname: "",
    lname: "",
    email: "",
    phone: "",
    address: "",
    addressline1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    social: "",
    dob: "",
    stateDriver: "",
    stateIssued: "",
    eligible: "",
    age: "",
    pled: "",
    pledReason: "",
    startDate: "",
    availabledays: "",
    shift: "",
    transportation: "",
    vehicleYear: "",
    insurance: "",
    commission: "",
    travel: "",
    hired: "",
    employerName: "",
    employerAddress: "",
    employerAddress1: "",
    employerAddress2: "",
    employerCity: "",
    employerState: "",
    employerZip: "",
    employerPhone: "",
    employerManager: "",
    employerSDate: "",
    employerEDate: "",
    employerPosition: "",
    employerSalary: "",
    employerReason: "",
    employerContact: "",
    additional: "",
    file: "",
    agreement: "",
  };

  const [formData, setFormData] = useState(initialValues);
  const [isLoader, setIsLoader] = useState(false);
  const [isApiResult, setIsApiResult] = useState({
    show: false,
    type: 1,
    message: "",
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    if (name === "file") {
      setFormData({ ...formData, file: e.target.files[0] });
    }
    // else if (name === "availabledays") {
    //   let days = [];
    //   days.push(e.target.value);
    //   setFormData({ ...formData, availabledays: days });
    // }
    else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const resetValues = () => {
    formData.positionApply = "";
    formData.positionType = "";
    formData.fname = "";
    formData.lname = "";
    formData.email = "";
    formData.phone = "";
    formData.address = "";
    formData.addressline1 = "";
    formData.addressLine2 = "";
    formData.city = "";
    formData.state = "";
    formData.zip = "";
    formData.social = "";
    formData.dob = "";
    formData.stateDriver = "";
    formData.stateIssued = "";
    formData.eligible = "";
    formData.age = "";
    formData.pled = "";
    formData.pledReason = "";
    formData.startDate = "";
    formData.availabledays = [];
    formData.shift = "";
    formData.transportation = "";
    formData.vehicleYear = "";
    formData.insurance = "";
    formData.commission = "";
    formData.travel = "";
    formData.hired = "";
    formData.employerName = "";
    formData.employerAddress = "";
    formData.employerAddress1 = "";
    formData.employerAddress2 = "";
    formData.employerCity = "";
    formData.employerState = "";
    formData.employerZip = "";
    formData.employerPhone = "";
    formData.employerManager = "";
    formData.employerSDate = "";
    formData.employerEDate = "";
    formData.employerPosition = "";
    formData.employerSalary = "";
    formData.employerReason = "";
    formData.employerContact = "";
    formData.additional = "";
    formData.file = "";
    formData.agreement = "";
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setIsLoader(true);

    emailjs
      .sendForm(
        "service_h43q1i5",
        "template_4swail6",
        form.current,
        "dHLCEzRKfc5Pd_JI-"
      )
      .then((response) => {
        setIsLoader(false);
        setIsApiResult({
          show: true,
          type: 1,
          message: "Thanks we will contact you shortly !!!",
        });

        resetValues();
      })
      .catch((error) => {
        setIsLoader(false);
        setIsApiResult({
          show: true,
          type: 2,
          message: "Something went wrong. Please try again later.",
        });
      });
    // const reader = new FileReader();
    // reader.readAsDataURL(formData.file);

    // reader.onload = async (e) => {
    //   initialValues.file = reader.result;

    // };
  };

  const typeOfPosition = [
    {
      id: formData.positionType,
      name: "positionType",
      value: "Part Time",
      label: "Part Time",
    },
    {
      id: formData.positionType,
      name: "positionType",
      value: "Full Time",
      label: "Full Time",
    },
  ];
  const personalInfo = [
    {
      id: formData.fname,
      label: "First Name",
      name: "fname",
      value: formData.fname,
      required: true,
      placeholder: "Enter your first name",
    },
    {
      id: formData.lname,
      label: "Last Name",
      name: "lname",
      value: formData.lname,
      required: true,
      placeholder: "Enter your last name",
    },
    {
      id: formData.email,
      label: "Email",
      name: "email",
      value: formData.email,
      required: true,
      placeholder: "Enter Your Email",
    },
    {
      id: formData.phone,
      label: "Phone Number",
      name: "phone",
      value: formData.phone,
      required: true,
      placeholder: "Enter Your Phone Number",
    },
    {
      id: formData.address,
      label: "Address",
      name: "address",
      value: formData.address,
      required: true,
      placeholder: "Enter Your Full Address",
    },
    {
      id: formData.addressline1,
      label: "Address Line 1",
      name: "addressline1",
      value: formData.addressline1,
      required: false,
      placeholder: "Enter Your Full Address Line 1",
    },
    {
      id: formData.addressLine2,
      label: "Address Line 2",
      name: "addressLine2",
      value: formData.addressLine2,
      required: false,
      placeholder: "Enter Your Full Address Line 2",
    },
    {
      id: formData.city,
      label: "City",
      name: "city",
      value: formData.city,
      required: false,
      placeholder: "Enter Your City",
    },
    {
      id: formData.zip,
      label: "Zip Code",
      name: "zip",
      value: formData.zip,
      required: false,
      placeholder: "Enter your zip code",
    },
    {
      id: formData.state,
      label: "State",
      name: "state",
      value: formData.state,
      required: false,
      placeholder: "Enter your state",
    },
    {
      id: formData.social,
      label: "Social security:",
      name: "social",
      value: formData.social,
      required: false,
      placeholder: "Enter your social security",
    },
    {
      id: formData.dob,
      label: "Date of birth",
      name: "dob",
      type: "date",
      value: formData.dob,
      required: false,
      placeholder: "09/22/1999",
    },
  ];
  const availableDays = [
    {
      id: formData.availabledays,
      name: "availabledays",
      value: "Monday",
      required: true,
      label: "Monday",
    },
    {
      id: formData.availabledays,
      name: "availabledays",
      value: "Tuesday",
      required: true,
      label: "Tuesday",
    },
    {
      id: formData.availabledays,
      name: "availabledays",
      value: "Wednesday",
      required: true,
      label: "Wednesday",
    },
    {
      id: formData.availabledays,
      name: "availabledays",
      value: "Thursday",
      required: true,
      label: "Thursday",
    },
    {
      id: formData.availabledays,
      name: "availabledays",
      value: "Friday",
      required: true,
      label: "Friday",
    },
    {
      id: formData.availabledays,
      name: "availabledays",
      value: "Saturday",
      required: true,
      label: "Saturday",
    },
    {
      id: formData.availabledays,
      name: "availabledays",
      value: "Sunday",
      required: true,
      label: "Sunday",
    },
  ];
  const shifts = [
    {
      id: formData.shift,
      name: "shift",
      value: "Morning",
      required: true,
      label: "Morning",
    },
    {
      id: formData.shift,
      name: "shift",
      value: "Evening",
      required: true,
      label: "Evening",
    },
    {
      id: formData.shift,
      name: "shift",
      value: "Overnight",
      required: true,
      label: "Overnight",
    },
    {
      id: formData.shift,
      name: "shift",
      value: "Any",
      required: true,
      label: "Any",
    },
  ];
  const incotrpl = [
    {
      label: "Can you provide Proof of liability Insurance",
      required: true,
      options: [
        {
          id: formData.insurance,
          name: "insurance",
          value: "Yes",
          required: true,
          label: "Yes",
        },
        {
          id: formData.insurance,
          name: "insurance",
          value: "No",
          required: true,
          label: "No",
        },
      ],
    },
    {
      label:
        "Do you have have an active commissioned or non commissioned Security Officer License? ",
      required: false,
      options: [
        {
          id: formData.commission,
          name: "commission",
          value: "Yes",
          required: true,
          label: "Yes",
        },
        {
          id: formData.commission,
          name: "commission",
          value: "No",
          required: true,
          label: "No",
        },
      ],
    },
    {
      label: "Are you willing to travel over 20 miles to work?",
      required: true,
      options: [
        {
          id: formData.travel,
          name: "travel",
          value: "Yes",
          required: true,
          label: "Yes",
        },
        {
          id: formData.travel,
          name: "travel",
          value: "No",
          required: true,
          label: "No",
        },
      ],
    },
    {
      label:
        "If hired, do you agree, that you will not make any travel plans without written approval from the director of operations?",
      required: false,
      options: [
        {
          id: formData.hired,
          name: "hired",
          value: "Yes",
          required: true,
          label: "Yes",
        },
        {
          id: formData.hired,
          name: "hired",
          value: "No",
          required: true,
          label: "No",
        },
      ],
    },
  ];
  const employment = [
    {
      id: formData.employerName,
      label: "Most Recent Employer Name / Company Name",
      name: "employerName",
      value: formData.employerName,
      required: true,
      placeholder: "Enter Your company name",
    },
    {
      id: formData.employerAddress,
      label: "Address",
      name: "employerAddress",
      value: formData.employerAddress,
      required: true,
      placeholder: "Enter Your  Full Address",
    },
    {
      id: formData.employerAddress1,
      label: "Address Line 1",
      name: "employerAddress1",
      value: formData.employerAddress1,
      required: false,
      placeholder: "Enter address line 1",
    },
    {
      id: formData.employerAddress2,
      label: "Address Line 2",
      name: "employerAddress2",
      value: formData.employerAddress2,
      required: false,
      placeholder: "Enter address line 2",
    },
    {
      id: formData.employerCity,
      label: "City",
      name: "employerCity",
      value: formData.employerCity,
      required: false,
      placeholder: "Enter your city",
    },
    {
      id: formData.employerState,
      label: "State",
      name: "employerState",
      value: formData.employerState,
      required: false,
      placeholder: "Enter your state",
    },
    {
      id: formData.employerZip,
      label: "Zip Code",
      name: "employerZip",
      value: formData.employerZip,
      required: false,
      placeholder: "Enter your zip code",
    },
    {
      id: formData.employerPhone,
      label: "Phone Number",
      name: "employerPhone",
      value: formData.employerPhone,
      required: false,
      placeholder: "Enter Your  Phone Number",
    },
    {
      id: formData.employerManager,
      label: "Name of supervisor / Manager",
      name: "employerManager",
      value: formData.employerManager,
      required: true,
      placeholder: "Enter your manager name",
    },
    {
      id: formData.employerSDate,
      label: "Start Date",
      type: "date",
      name: "employerSDate",
      value: formData.employerSDate,
      required: false,
      placeholder: "Enter start date",
    },
    {
      id: formData.employerEDate,
      label: "End Date",
      type: "date",
      name: "employerEDate",
      value: formData.employerEDate,
      required: false,
      placeholder: "Enter start date",
    },
    {
      id: formData.employerPosition,
      label: "Position",
      name: "employerPosition",
      value: formData.employerPosition,
      required: false,
      placeholder: "Enter your position",
    },
    {
      id: formData.employerSalary,
      label: "Salary",
      name: "employerSalary",
      value: formData.employerSalary,
      required: false,
      placeholder: "Enter your salary",
    },
    {
      id: formData.employerReason,
      label: "Reason For leaving",
      name: "employerReason",
      value: formData.employerReason,
      required: false,
      placeholder: "Enter your reason",
    },
  ];

  return (
    <Container className={Styles.careerForm}>
      <h1 className={Styles.heading}>We are hiring !</h1>
      <p className={Styles.subtext}>
        Homeland protective service inc is currently accepting applicants to
        fill available commissioned and non commissioned positions. to ensure
        proper consideration. please fill the form in the information below as
        completely and accurately as possible
      </p>
      <form ref={form}>
        <div className={Styles.formItems}>
          <Row>
            <Col md="4">
              <DropDownInput
                id={formData.positionApply}
                label="Position Applying For"
                name="positionApply"
                required={true}
                onChange={handleOnChange}
                options={[
                  "Non-Commissioner officer",
                  "Commissioner officer",
                  "Receptionist",
                  "Sales Person",
                ]}
              />
            </Col>
          </Row>
          <Row>
            <p className={Styles.inputHeading}>Type Of Position</p>
            {typeOfPosition.length &&
              typeOfPosition.map((data, _idx) => (
                <Col md="4">
                  <RadioInput
                    id={data.id}
                    name={data.name}
                    value={data.value}
                    required={false}
                    onChange={handleOnChange}
                    label={data.label}
                  />
                </Col>
              ))}
          </Row>
          <Row>
            {personalInfo.length &&
              personalInfo.map((data, _idx) => (
                <Col md="6">
                  <TextInput
                    label={data.label}
                    id={data.id}
                    type={data.type ? data.type : "text"}
                    name={data.name}
                    value={data.value}
                    required={data.required}
                    placeholder={data.placeholder}
                    onChange={handleOnChange}
                  />
                </Col>
              ))}
          </Row>
          <Row>
            <p className={Styles.inputHeading}>Questionnaire</p>
            <p className={Styles.inputSubHeading}>
              Do you have a valid state driver license?
            </p>
            <Col md="4">
              <RadioInput
                id={formData.stateDriver}
                name="stateDriver"
                value="Yes"
                required={false}
                onChange={handleOnChange}
                label="Yes"
              />
            </Col>
            <Col md="4">
              <RadioInput
                id={formData.stateDriver}
                name="stateDriver"
                value="No"
                required={false}
                onChange={handleOnChange}
                label="No"
              />
            </Col>
          </Row>
          {formData.stateDriver === "Yes" && (
            <Row>
              <Col md="12">
                <TextInput
                  label="If yes, then please provide Driver's license No: and state issued"
                  id={formData.stateIssued}
                  type="text"
                  name="stateIssued"
                  value={formData.stateIssued}
                  required={false}
                  onChange={handleOnChange}
                  placeholder="Provide reason here"
                />
              </Col>
            </Row>
          )}
          <Row>
            <p className={Styles.inputSubHeading}>
              Are you legally eligible to work in the united states?
            </p>
            <Col md="4">
              <RadioInput
                id={formData.eligible}
                name="eligible"
                value="Yes"
                required={false}
                onChange={handleOnChange}
                label="Yes"
              />
            </Col>
            <Col md="4">
              <RadioInput
                id={formData.eligible}
                name="eligible"
                value="No"
                required={false}
                onChange={handleOnChange}
                label="No"
              />
            </Col>
          </Row>
          <Row>
            <p className={Styles.inputSubHeading}>
              Are you over the Age of 18? <span>*</span>
            </p>
            <Col md="4">
              <RadioInput
                id={formData.age}
                name="age"
                value="Yes"
                required={true}
                onChange={handleOnChange}
                label="Yes"
              />
            </Col>
            <Col md="4">
              <RadioInput
                id={formData.age}
                name="age"
                value="No"
                required={true}
                onChange={handleOnChange}
                label="No"
              />
            </Col>
          </Row>
          <Row>
            <p className={Styles.inputSubHeading}>
              Have you ever pled guilty, no contest or been convicted of a
              crime? <span>*</span>
            </p>
            <Col md="4">
              <RadioInput
                id={formData.pled}
                name="pled"
                value="Yes"
                required={true}
                onChange={handleOnChange}
                label="Yes"
              />
            </Col>
            <Col md="4">
              <RadioInput
                id={formData.pled}
                name="pled"
                value="No"
                required={true}
                onChange={handleOnChange}
                label="No"
              />
            </Col>
          </Row>
          {formData.pled === "Yes" && (
            <TextAreaInput
              id={formData.pledReason}
              label="If yes, then please Explain."
              name="pledReason"
              value={formData.pledReason}
              required={false}
              placeholder="Type reasone here"
              onChange={handleOnChange}
            />
          )}
          <Row>
            <p className={Styles.inputHeading}>Preferences and Availability</p>
            <Col md="4">
              <TextInput
                label="Date you can Start?"
                id={formData.startDate}
                type="date"
                name="startDate"
                value={formData.startDate}
                required={true}
                placeholder="Select date"
                onChange={handleOnChange}
              />
            </Col>
          </Row>
          <Row>
            <p className={Styles.inputSubHeading}>
              Days Avaialable To work <span>*</span>
            </p>
            {availableDays.length &&
              availableDays.map((data, _idx) => (
                <Col md="4">
                  <RadioInput
                    id={data.id}
                    name={data.name}
                    value={data.value}
                    required={data.required}
                    onChange={handleOnChange}
                    label={data.label}
                  />
                </Col>
              ))}
          </Row>
          <Row>
            <p className={Styles.inputSubHeading}>
              Shift Needed <span>*</span>
            </p>
            {shifts.length &&
              shifts.map((data, _idx) => (
                <Col md="3">
                  <RadioInput
                    id={data.id}
                    name={data.name}
                    value={data.value}
                    required={data.required}
                    onChange={handleOnChange}
                    label={data.label}
                  />
                </Col>
              ))}
          </Row>
          <Row>
            <p className={Styles.inputSubHeading}>
              Do you have any reliable Transportation? (NO RIDE SHARING OR
              METRO)
            </p>
            <Col md="4">
              <RadioInput
                id={formData.transportation}
                name="transportation"
                value="Yes"
                required={false}
                onChange={handleOnChange}
                label="Yes"
              />
            </Col>
            <Col md="4">
              <RadioInput
                id={formData.transportation}
                name="transportation"
                value="No"
                required={false}
                onChange={handleOnChange}
                label="No"
              />
            </Col>
          </Row>
          {formData.transportation === "Yes" && (
            <Row>
              <Col md="4">
                <TextInput
                  label="if yes, Year, Make and model of vehicle."
                  id={formData.vehicleYear}
                  type="text"
                  name="vehicleYear"
                  value={formData.vehicleYear}
                  required={true}
                  placeholder="Enter vehicle year / model / make"
                  onChange={handleOnChange}
                />
              </Col>
            </Row>
          )}
          <Row>
            {incotrpl.length &&
              incotrpl.map((data, _idx) => (
                <>
                  <p className={Styles.inputSubHeading}>
                    {data.label} {data.required && <span>*</span>}
                  </p>
                  {data.options.length &&
                    data.options.map((opt, _index) => (
                      <Col md="4">
                        <RadioInput
                          id={opt.id}
                          name={opt.name}
                          value={opt.value}
                          required={opt.required}
                          onChange={handleOnChange}
                          label={opt.label}
                        />
                      </Col>
                    ))}
                </>
              ))}
          </Row>
          <Row>
            <p className={Styles.inputHeading}>Employment History </p>
            {employment.length &&
              employment.map((data, _idx) => (
                <Col md="6">
                  <TextInput
                    label={data.label}
                    id={data.id}
                    type={data.type ? data.type : "text"}
                    name={data.name}
                    value={data.value}
                    required={data.required}
                    placeholder={data.placeholder}
                    onChange={handleOnChange}
                  />
                </Col>
              ))}
          </Row>
          <Row>
            <p className={Styles.inputSubHeading}>May we contact?</p>
            <Col md="4">
              <RadioInput
                id={formData.employerContact}
                name="employerContact"
                value="Yes"
                required={false}
                onChange={handleOnChange}
                label="Yes"
              />
            </Col>
            <Col md="4">
              <RadioInput
                id={formData.employerContact}
                name="employerContact"
                value="No"
                required={false}
                onChange={handleOnChange}
                label="No"
              />
            </Col>
          </Row>
          <Row>
            <p className={Styles.inputHeading}>Additional Information</p>
            <TextAreaInput
              id={formData.additional}
              label="Please provide any additional skills or certificate"
              name="additional"
              value={formData.additional}
              required={false}
              placeholder="Type additional skills etc here"
              onChange={handleOnChange}
            />
          </Row>
          <Row>
            <p className={Styles.inputHeading}>Resume Upload</p>
            <input
              type="file"
              // name="file"
              // onChange={handleOnChange}
              // required={true}
            />
          </Row>
          <Row>
            <p className={Styles.inputSubHeading}>
              By Submitting your information, you acknowledge that all
              representation made by you are true contact to the best of your
              knowledge and belief, and you have not knowingly omitted any
              related information of an adverse nature .providing any inaccurate
              information may make you ineligble for employment <span>*</span>
            </p>
            <Col md="4">
              <RadioInput
                id={formData.agreement}
                name="agreement"
                value="I agree"
                required={true}
                onChange={handleOnChange}
                label="I agree"
              />
            </Col>
            <Col md="4">
              <RadioInput
                id={formData.agreement}
                name="agreement"
                value="No, I do Not agree"
                required={true}
                onChange={handleOnChange}
                label="No, I do Not agree"
              />
            </Col>
          </Row>
          <br />
          <Button title="Submit" onClick={handleOnSubmit} />
        </div>
      </form>
      <br />
      <Loader show={isLoader} />
      <AlertBox
        isShow={isApiResult.show}
        type={isApiResult.type}
        message={isApiResult.message}
      />
    </Container>
  );
};

export default CareerForm;
