import React from "react";
import Styles from "./style.module.scss";
import { Container, Row, Col, Image } from "react-bootstrap";
import MissionOne from "../../Assets/Images/mission_img1.jpg";
import MissionTwo from "../../Assets/Images/mission_img2.png";
import Button from "../../Components/Button";
import getWindowDimensions from "../../Utils/screenSize";

const Mission = () => {
  const { width } = getWindowDimensions();
  return (
    <Container className={Styles.missionContainer}>
      <div className={Styles.missionHeader}>
        Total Security Solutions: Protecting People, Places, and Prestige
      </div>
      <Row className={` ${Styles.missionMain}`}>
        <Col md="6">
          <div className={Styles.missionDesc1}>
            <p className={Styles.heading}>About Us</p>
            <p className={Styles.description}>
              At Homeland Protect, we're dedicated to keeping you safe. With our
              expert team andstate-of-the-art technology, we're here to meet
              your security requirements with utmost efficiency and care. From
              armed or unarmed security and surveillance solutions to
              comprehensive patrols, we've got you covered. Trust Homeland
              Protect for peace of mind in an uncertain world.
            </p>
            <Button link="/about" title="Read More" />
          </div>
        </Col>
        <Col md="6" className={Styles.missionImage1}>
          <img src={MissionOne} alt="Mission 1" />
        </Col>
      </Row>
      <Row className={`${Styles.missionMain}`}>
        {width > 1024 ? (
          <>
            <Col md="6" className={Styles.missionImage2}>
              <img src={MissionTwo} alt="Mission 2" />
            </Col>
            <Col md="6">
              <div className={Styles.missionDesc2Box}>
                <div className={Styles.missionDesc2}>
                  <p className={Styles.heading}>Request Service</p>
                  <p className={Styles.description}>
                    Ready to Secure Your Peace of Mind? Request Service with
                    Homeland Protect Today. Our professional team is here to
                    address your security needs swiftly and effectively. Whether
                    it's protecting your premises, installing surveillance
                    systems, or providing specialized patrols, we're at your
                    service.
                  </p>
                  <Button link="/requestservice" title="Request Service" />
                </div>
              </div>
            </Col>
          </>
        ) : (
          <>
            <Col md="6">
              <div className={Styles.missionDesc2Box}>
                <div className={Styles.missionDesc2}>
                  <p className={Styles.heading}>Request Service</p>
                  <p className={Styles.description}>
                    Ready to Secure Your Peace of Mind? Request Service with
                    Homeland Protect Today. Our professional team is here to
                    address your security needs swiftly and effectively. Whether
                    it's protecting your premises, installing surveillance
                    systems, or providing specialized patrols, we're at your
                    service.
                  </p>
                  <Button link="/requestservice" title="Request Service" />
                </div>
              </div>
            </Col>
            <Col md="6" className={Styles.missionImage2}>
              <img src={MissionTwo} alt="Mission 2" />
            </Col>
          </>
        )}
      </Row>
      <div className={Styles.missionExclusive}>
        <Row className="align-items-center">
          <Col md="8">
            <div className={Styles.description}>
              <p className={Styles.heading}>What Makes Us Exclusive</p>
              <p className={Styles.content}>
                Our unique blend of expertise, innovation, and personalized
                service sets us apart in the security industry. From advanced
                surveillance options to responsive round-the-clock customer
                support,we're committed to providing you with the highest level
                of protection.
              </p>
            </div>
          </Col>
          <Col md="4" className={Styles.buttonContact}>
            <Button link="/contact" title="Contact Us" />
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Mission;
