import React from "react";
import { Container, Breadcrumb } from "react-bootstrap";
import Styles from "./style.module.scss";
import getWindowDimensions from "../../Utils/screenSize";

const HeaderWithImage = ({
  breadCrumb1,
  breadCrumb2,
  title,
  subtitle,
  subtitleSmall,
  normalImage,
  desktopBanner,
  mobileBanner,
}) => {
  const { width } = getWindowDimensions();

  return normalImage ? (
    <div className={Styles.headerMainNormalImage}>
      <Container>
        <Breadcrumb className={Styles.breadCrumbBox}>
          <Breadcrumb.Item className={Styles.breadCrumb} active>
            {breadCrumb1}
          </Breadcrumb.Item>
          <Breadcrumb.Item className={Styles.breadCrumb} active>
            {breadCrumb2}
          </Breadcrumb.Item>
        </Breadcrumb>
      </Container>
      <div className={Styles.headerBanner}>
        {width >= 1025 ? (
          <img
            src={desktopBanner}
            alt="desktop-banner"
            className={Styles.desktopBanner}
          />
        ) : (
          <img
            src={mobileBanner}
            alt="mobile-banner"
            className={Styles.mobileBanner}
          />
        )}
      </div>
      <Container>
        <h1 className={Styles.heading}>{title}</h1>
        {subtitle && <h2 className={Styles.subheading}>{subtitle}</h2>}
        {subtitleSmall && (
          <p className={Styles.subheadingText}>{subtitleSmall}</p>
        )}
      </Container>
    </div>
  ) : (
    <div
      className={Styles.headerMainImage}
      style={{
        backgroundImage: `url(${width >= 1025 ? desktopBanner : mobileBanner})`,
      }}
    >
      <Container>
        <Breadcrumb className={Styles.breadCrumbBox}>
          <Breadcrumb.Item className={Styles.breadCrumb} active>
            {breadCrumb1}
          </Breadcrumb.Item>
          <Breadcrumb.Item className={Styles.breadCrumb} active>
            {breadCrumb2}
          </Breadcrumb.Item>
        </Breadcrumb>
        <h1 className={Styles.heading}>{title}</h1>
        {subtitle && <h2 className={Styles.subheading}>{subtitle}</h2>}
        {subtitleSmall && (
          <p className={Styles.subheadingText}>{subtitleSmall}</p>
        )}
      </Container>
    </div>
  );
};

export default HeaderWithImage;
