import React from "react";
import Styles from "./style.module.scss";

const DropDownInput = ({
  id,
  label,
  name,
  value,
  required,
  placeholder,
  options,
  onChange,
}) => {
  return (
    <>
      {label && (
        <label className={Styles.DDLBoxLabel}>
          {label} <span>{required && "*"}</span>
        </label>
      )}
      <select
        id={id}
        className={Styles.selectBox}
        name={name}
        value={value}
        required={required ? required : false}
        placeholder={`${placeholder} ${required ? "*" : ""}`}
        onChange={onChange}
      >
        <option selected disabled value="">
          Please select
        </option>
        {options.length &&
          options.map((data, _idx) => (
            <option key={_idx} value={data}>
              {data}
            </option>
          ))}
      </select>
    </>
  );
};

export default DropDownInput;
