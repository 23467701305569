import React from "react";
import { Spinner } from "react-bootstrap";

const Loader = (props) => {
  const { show } = props;
  return (
    show && (
      <div style={{ width: "100%", textAlign: "center" }}>
        <Spinner animation="grow" variant="warning" />
      </div>
    )
  );
};

export default Loader;
