import React from "react";
import Styles from "./style.module.scss";

const TextAreaInput = ({
  id,
  label,
  name,
  value,
  placeholder,
  onChange,
  required,
}) => {
  return (
    <>
      {label && (
        <label className={Styles.textAreaLabel}>
          {label} {required && <span>*</span>}
        </label>
      )}
      <textarea
        id={id}
        className={Styles.textarea}
        name={name}
        value={value}
        required={required ? required : false}
        placeholder={`${placeholder} ${required && "*"}`}
        onChange={onChange}
      ></textarea>
    </>
  );
};

export default TextAreaInput;
