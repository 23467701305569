import React from "react";
import Styles from "./style.module.scss";
import { Container, Row, Col } from "react-bootstrap";

const Services = (props) => {
  return (
    <Container className={Styles.servicesContainer}>
      <Row>
        {props.services.length > 0 &&
          props.services.map((data, _idx) => (
            <Col key={_idx} md="3">
              <div className={Styles.cardMain}>
                <div className={Styles.cardInnerBox}>
                  <div className={Styles.icon}>
                    <img src={data.image} alt={data.title} />
                  </div>
                  <div className={Styles.title}>{data.title}</div>
                  <div className={Styles.description}>{data.description}</div>
                </div>
              </div>
            </Col>
          ))}
      </Row>
    </Container>
  );
};

export default Services;
