import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Styles from "./style.module.scss";

// Icons
import Icon1 from "../../Assets/Images/ind_1.png";
import Icon2 from "../../Assets/Images/ind_2.png";
import Icon3 from "../../Assets/Images/ind_3.png";
import Icon4 from "../../Assets/Images/ind_4.png";
import Icon5 from "../../Assets/Images/ind_5.png";
import Icon6 from "../../Assets/Images/ind_6.png";
import Icon7 from "../../Assets/Images/ind_7.png";
import Icon8 from "../../Assets/Images/ind_8.png";
import Icon9 from "../../Assets/Images/ind_9.png";
import Icon10 from "../../Assets/Images/ind_10.png";
import Icon11 from "../../Assets/Images/ind_11.png";

const industriesList = [
  {
    icon: Icon1,
    text: "Commercial Real Estate",
  },
  {
    icon: Icon2,
    text: "Government",
  },
  {
    icon: Icon3,
    text: "Healthcare",
  },
  {
    icon: Icon4,
    text: "Churches",
  },
  {
    icon: Icon5,
    text: "Manufacturing Sector",
  },
  {
    icon: Icon6,
    text: "Educational Institutions",
  },
  {
    icon: Icon7,
    text: "Data Centers",
  },
  {
    icon: Icon8,
    text: "Shopping Centers",
  },
  {
    icon: Icon9,
    text: "Construction Sites",
  },
  {
    icon: Icon10,
    text: "Financial Institutions",
  },
  {
    icon: Icon11,
    text: "Logistics Trucking",
  },
];

const Industries = () => {
  return (
    <Container className={Styles.industriesContainer}>
      <p className={Styles.heading}>Industries We Serve</p>
      <Row className="align-items-center justify-content-center">
        {industriesList.length > 0 &&
          industriesList.map((data, _idx) => (
            <Col md="2">
              <div className={Styles.box}>
                <img className={Styles.icon} src={data.icon} alt={data.text} />
                <p className={Styles.text}>{data.text}</p>
              </div>
            </Col>
          ))}
      </Row>
    </Container>
  );
};

export default Industries;
