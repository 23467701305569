import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Styles from "./style.module.scss";

import AboutImg1 from "../../Assets/Images/about-img1.png";
import AboutImg2 from "../../Assets/Images/about-img2.jpg";
import purpose1 from "../../Assets/Images/purpose1.png";
import purpose2 from "../../Assets/Images/purpose2.png";
import purpose3 from "../../Assets/Images/purpose3.png";
import purpose4 from "../../Assets/Images/purpose4.png";

const Section1 = () => {
  return (
    <Container className={Styles.section1}>
      <Row>
        <Col md="6">
          <div className={Styles.leftBox}>
            <img className={Styles.aboutImg1} src={AboutImg1} alt="About 1" />
            <Row>
              <Col md="6" style={{ textAlign: "center" }}>
                <Row>
                  <Col md="6" xs="6">
                    <div className={Styles.socialBox}>
                      <img src={purpose1} alt="Social Icon" />
                    </div>
                  </Col>
                  <Col md="6" xs="6">
                    <div className={Styles.socialBox}>
                      <img src={purpose2} alt="Social Icon" />
                    </div>
                  </Col>
                  <Col md="6" xs="6">
                    <div className={Styles.socialBox}>
                      <img src={purpose3} alt="Social Icon" />
                    </div>
                  </Col>
                  <Col md="6" xs="6">
                    <div className={Styles.socialBox}>
                      <img src={purpose4} alt="Social Icon" />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md="6">
                <div className={Styles.detailedBox}>
                  <h2 className={Styles.heading}>Our Purpose</h2>
                  <p className={Styles.description}>
                    Bringing Safety Closer to Home.
                  </p>
                  <p className={Styles.description}>
                    At Homeland Protect, our purpose is more than just a
                    statement – it's a commitment that drives every aspect of
                    our organization. From our skilled team members to our
                    dedicated support staff, we're united in our mission to
                    ensure your safety. With a global presence across
                    considerable markets, we come together each day to deliver
                    top-notch security solutions tailored to your needs. Whether
                    through our on-site protective services or innovative
                    technology solutions, your safety is at the heart of
                    everything we do, as your peace of mind is our ultimate
                    goal.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md="6">
          <div className={Styles.rightBox}>
            <Row>
              <Col md="6">
                <div className={Styles.detailedBox}>
                  <h2 className={Styles.heading}>Our Values</h2>
                  <p className={Styles.description}>
                    Navigating the Changing Tides of Security. In a world marked
                    by constant change and evolving threats, Homeland Protect
                    remains steadfast in its commitment to protecting what truly
                    matters. From the safety of individuals and their belongings
                    to the ongoing progress of society and the success of our
                    valued clients, our strategy is clear: Adaptability and
                    Vigilance.
                  </p>
                  <p className={Styles.description}>
                    {" "}
                      In an era defined by geopolitical shifts and technological
                    advancements, we remain agile, proactive, and ready to
                    address a multitude of security challenges. With our finger
                    on the pulse of emerging threats, we're dedicated to staying
                    one step ahead, ensuring your safety and security remain our
                    top priorities.
                  </p>
                </div>
              </Col>
              <Col md="6">
                <div className={Styles.infoBox}>
                  <p className={Styles.heading}>Our Mission</p>
                  <p className={Styles.description}>
                    At Homeland Protect, we're driven by a powerful mission – to
                    ensure your peace of mind. Every action we take and the
                    solution we offer is committed to keeping you and your
                    assets safe. With unwavering dedication, we strive to create
                    a world where security is never compromised.
                  </p>
                </div>
                <div className={Styles.infoBox}>
                  <p className={Styles.heading}>Our Members</p>
                  <p className={Styles.description}>
                    Meet our visionary Board Members, driving Homeland Protect's
                    Innovation and Success. With diverse expertise and
                    unwavering dedication, our board shapes strategic decisions
                    to ensure top-notch security solutions for our clients.
                  </p>
                </div>
              </Col>
            </Row>
            <img className={Styles.aboutImg2} src={AboutImg2} alt="About 2" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Section1;
