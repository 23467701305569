import React, { useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Styles from "./style.module.scss";
import TextInput from "../../Components/Inputs/TextInput";
import TextAreaInput from "../../Components/Inputs/TextareaInput";
import Button from "../../Components/Button";
import RadioInput from "../../Components/Inputs/RadioInput";
import emailjs from "@emailjs/browser";
import AlertBox from "../../Components/Alert";
import Loader from "../../Components/Loader";

const ConsultancyForm = () => {
  const form = useRef();

  const initialValues = {
    clientname: "",
    companyname: "",
    phone: "",
    email: "",
    location: "",
    services: "",
    hours: "",
    security: "",
    servicemsg: "",
    description: "",
  };

  const [formData, setFormData] = useState(initialValues);
  const [isLoader, setIsLoader] = useState(false);
  const [isApiResult, setIsApiResult] = useState({
    show: false,
    type: 1,
    message: "",
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setIsLoader(true);

    emailjs
      .sendForm(
        "service_h43q1i5",
        "template_ohsqsq8",
        form.current,
        "dHLCEzRKfc5Pd_JI-"
      )
      .then((response) => {
        setIsLoader(false);
        setIsApiResult({
          show: true,
          type: 1,
          message: "Email sent successfully !!!",
        });

        formData.clientname = "";
        formData.companyname = "";
        formData.email = "";
        formData.phone = "";
        formData.location = "";
        formData.services = "";
        formData.hours = "";
        formData.security = "";
        formData.servicemsg = "";
        formData.description = "";
      })
      .catch((error) => {
        setIsLoader(false);
        setIsApiResult({
          show: true,
          type: 2,
          message: "Something went wrong !!!",
        });
      });
  };

  return (
    <Container className={Styles.consultancyForm}>
      <form ref={form}>
        <h1 className={Styles.heading}>Contact Information</h1>
        <Row>
          <Col md="6">
            <TextInput
              id={formData.clientName}
              type="text"
              name="clientname"
              value={formData.clientname}
              required={false}
              placeholder="Client Name"
              onChange={handleOnChange}
            />
          </Col>
          <Col md="6">
            <TextInput
              id={formData.companyname}
              type="text"
              name="companyname"
              value={formData.companyname}
              required={false}
              placeholder="Company Name"
              onChange={handleOnChange}
            />
          </Col>
          <Col md="6">
            <TextInput
              id={formData.phone}
              type="text"
              name="phone"
              value={formData.phone}
              required={false}
              placeholder="Phone"
              onChange={handleOnChange}
            />
          </Col>
          <Col md="6">
            <TextInput
              id={formData.email}
              type="text"
              name="email"
              value={formData.email}
              required={false}
              placeholder="Email"
              onChange={handleOnChange}
            />
          </Col>
        </Row>
        <h1 className={Styles.heading}>Type Of Security Needed</h1>
        <p className={Styles.subheading}>Type of location</p>
        <Row className={Styles.rows_inputs}>
          <Col md="4">
            <RadioInput
              id={formData.location}
              name="location"
              value="Commercial"
              required={false}
              onChange={handleOnChange}
              label="Commercial"
            />
          </Col>
          <Col md="4">
            <RadioInput
              id={formData.location}
              name="location"
              value="Residential"
              required={false}
              onChange={handleOnChange}
              label="Residential"
            />
          </Col>
          <Col md="4">
            <RadioInput
              id={formData.location}
              name="location"
              value="Other"
              required={false}
              onChange={handleOnChange}
              label="Other"
            />
          </Col>
        </Row>
        <p className={Styles.subheading}>
          Duration of Security Services Needed
        </p>
        <Row>
          <Col md="4">
            <RadioInput
              id={formData.services}
              name="services"
              value="One time"
              required={false}
              onChange={handleOnChange}
              label="One time"
            />
          </Col>
          <Col md="4">
            <RadioInput
              id={formData.services}
              name="services"
              value="Long term"
              required={false}
              onChange={handleOnChange}
              label="Long term"
            />
          </Col>
          <Col md="4">
            <RadioInput
              id={formData.services}
              name="services"
              value="Residential"
              required={false}
              onChange={handleOnChange}
              label="Residential"
            />
          </Col>
        </Row>
        <p className={Styles.subheading}>
          Are there specific hours or events during which you require security
          services?{" "}
        </p>
        <Row>
          <Col md="3">
            <RadioInput
              id={formData.hours}
              name="hours"
              value="Daytime"
              required={false}
              onChange={handleOnChange}
              label="Daytime"
            />
          </Col>
          <Col md="3">
            <RadioInput
              id={formData.hours}
              name="hours"
              value="nighttime"
              required={false}
              onChange={handleOnChange}
              label="nighttime"
            />
          </Col>
          <Col md="3">
            <RadioInput
              id={formData.hours}
              name="hours"
              value="24/7"
              required={false}
              onChange={handleOnChange}
              label="24/7"
            />
          </Col>
          <Col md="3">
            <RadioInput
              id={formData.hours}
              name="hours"
              value="during specific events"
              required={false}
              onChange={handleOnChange}
              label="during specific events"
            />
          </Col>
        </Row>
        <p className={Styles.subheading}>What Kind of security required?</p>
        <Row>
          <Col md="3">
            <RadioInput
              id={formData.security}
              name="security"
              value="Armed Security officer"
              required={false}
              onChange={handleOnChange}
              label="Armed Security officer"
            />
          </Col>
          <Col md="3">
            <RadioInput
              id={formData.security}
              name="security"
              value="unarmed Security officer"
              required={false}
              onChange={handleOnChange}
              label="unarmed Security officer"
            />
          </Col>
        </Row>
        <h1 className={Styles.heading}>Security Needs Assessment</h1>
        <TextInput
          id={formData.servicemsg}
          type="text"
          name="servicemsg"
          value={formData.servicemsg}
          required={false}
          placeholder="How soon do you need the security services to commence?"
          onChange={handleOnChange}
        />
        <TextAreaInput
          id={formData.description}
          type="text"
          name="description"
          value={formData.description}
          required={false}
          placeholder="Brief Description of Guard Duties"
          onChange={handleOnChange}
        />
        <div className={Styles.btnMain}>
          <Button title="Submit" onClick={handleOnSubmit} />
        </div>
      </form>
      <br />
      <Loader show={isLoader} />
      <AlertBox
        isShow={isApiResult.show}
        type={isApiResult.type}
        message={isApiResult.message}
      />
    </Container>
  );
};

export default ConsultancyForm;
