import React from "react";
import { Container, Carousel } from "react-bootstrap";
import Styles from "./style.module.scss";
import Slide1 from "../../Assets/Images/slider1.jpg";

const Slider = (props) => {
  return (
    <Container className={Styles.container}>
      <img className="d-block w-100" src={Slide1} alt="First slide" />
      {/* <Carousel data-bs-theme="dark">
        <Carousel.Item>
          <img className="d-block w-100" src={Slide1} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Slide1} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Slide1} alt="Third slide" />
        </Carousel.Item>
      </Carousel> */}
    </Container>
  );
};

export default Slider;
