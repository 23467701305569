import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Styles from "./style.module.scss";

import AboutImg5 from "../../Assets/Images/about-img5.png";
import Button from "../../Components/Button";

const Section4 = () => {
  return (
    <Container className={Styles.section4}>
      <Row>
        <Col md="6">
          <img className={Styles.aboutImg6} src={AboutImg5} alt="About 5" />
        </Col>
        <Col md="6">
          <div className={Styles.detailBox}>
            <p className={Styles.heading}>Join Our Team</p>
            <p className={Styles.description}>
              At Homeland Protect, we're more than just a company – we're a
              community dedicated to making the world a safer place. Join us in
              our mission to protect lives and assets while fostering innovation
              and growth. With diverse opportunities for growth and a supportive
              team environment, Homeland Protect offers a rewarding career where
              you can make a real difference. Explore our openings and become
              part of our passionate team today.
            </p>
            <div className={Styles.btnBox}>
              <Button link="#" title="Join Now" />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Section4;
