import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Styles from "./style.module.scss";

import Tick from "../../Assets/Images/tic-green.png";
import SecurityForm from "./SecurityForm";

const Section1 = () => {
  return (
    <Container className={Styles.section1security}>
      <h1 className={Styles.heading}>Security Camera Trailer </h1>
      <p className={Styles.description}>
        Enhance Your Securitywith Our State-of-the-Art Security Camera Trailer.
        Experience eternal peace ofmind with 24/7 surveillance, motion detection
        alerts, and remote access tostored footage. Solar-powered and
        user-friendly, our trailers provide seamlessprotection for your
        property. Upgrade your security today.
      </p>
      <p className={Styles.description}>
        Our cutting-edgesecurity camera trailers are well-equipped with features
        such as:
      </p>

      <div className={Styles.listOptions}>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            24/7 Recording for Continuous Security.
          </p>
        </div>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            Motion Detection that sends automated alerts via Email or Text.
          </p>
        </div>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            Solar-powered solutions that require no gas and keep costs down 
          </p>
        </div>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            Effortless view and recovery of stored video remotely. 
          </p>
        </div>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            Enjoy peace of mind with Ongoing Technical Support throughout your
            rental.
          </p>
        </div>
      </div>
      <div className={Styles.securityForm}>
        <h1 className={Styles.heading}>Contact Our Team</h1>
        <SecurityForm />
      </div>
    </Container>
  );
};

export default Section1;
