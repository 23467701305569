import React, { useState } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
// Pages
import HomePage from "../Containers/Home";
import About from "../Containers/About";
import ContactUs from "../Containers/Contact";
import Services from "../Containers/Services";
import RequestServices from "../Containers/RequestService";
import SecurityTrailer from "../Containers/SecurityCamera";
import FreeConsultancy from "../Containers/FreeConsultancy";
import Blogs from "../Containers/Blogs";
import BlogDetailed from "../Containers/BlogDetailed";
import SecurityGuard from "../Containers/Services/SecurityGuarding";
import AviationSecurity from "../Containers/Services/AviationSecurity";
import RemoteVideo from "../Containers/Services/RemoteVideo";
import ElectronicSecurity from "../Containers/Services/ElectronicSecurity";
import FireAndSafety from "../Containers/Services/FireAndSafety";
import CareersPage from "../Containers/Careers";

const routes = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/contact",
    element: <ContactUs />,
  },
  {
    path: "/services",
    element: <Services />,
  },
  {
    path: "/requestservice",
    element: <RequestServices />,
  },
  {
    path: "/consultancy",
    element: <FreeConsultancy />,
  },
  {
    path: "/blogs",
    element: <Blogs />,
  },
  {
    path: "/blogdetail",
    element: <BlogDetailed />,
  },
  {
    path: "/security",
    element: <SecurityTrailer />,
  },
  {
    path: "/service-security-guard",
    element: <SecurityGuard />,
  },
  {
    path: "/service-remote-video",
    element: <RemoteVideo />,
  },
  {
    path: "/service-aviation-security",
    element: <AviationSecurity />,
  },
  {
    path: "/service-electronic-security",
    element: <ElectronicSecurity />,
  },
  {
    path: "/service-fire-safety",
    element: <FireAndSafety />,
  },
  {
    path: "/careers",
    element: <CareersPage />,
  },
];

const AppRoutes = () => {
  const [loader, setLoader] = useState(false);
  return (
    <Router>
      <Routes>
        {routes.length > 0 &&
          routes.map((data, _idx) => (
            <Route
              loader={"loading"}
              key={_idx}
              path={data.path}
              element={data.element}
            />
          ))}
      </Routes>
    </Router>
  );
};

export default AppRoutes;
