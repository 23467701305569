import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Styles from "./style.module.scss";

import AboutImg3 from "../../Assets/Images/about-img3.jpg";
import AboutImg4 from "../../Assets/Images/about-img4.jpg";

const Section3 = () => {
  return (
    <Container className={Styles.section3}>
      <Row className="align-items-center">
        <Col md="6">
          <img className={Styles.aboutImg3} src={AboutImg3} alt="About 3" />
        </Col>
        <Col md="6">
          <div className={Styles.rightBox}>
            <p className={Styles.title}>Learn More About Homeland</p>
            <p className={Styles.description}>
              At Homeland Protect, we're dedicated to keeping you safe. With our
              expert team andstate-of-the-art technology, we're here to meet
              your security requirements with utmost efficiencyand care. From
              armed or unarmed security and surveillance solutions to
              comprehensive patrols,we've got you covered. Trust Homeland
              Protect for peace of mind in an uncertain world.
            </p>
          </div>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md="6">
          <div className={Styles.secondleft}>
            <img className={Styles.secAbout4} src={AboutImg4} alt="About 4" />
            <p className={Styles.description}>
              With a global presence across considerable markets, we come
              together each day to deliver top-notch security solutions tailored
              to your needs. Whether through our on-site protective services or
              innovative technology solutions, your safety is at the heart of
              everything we do, as your peace of mind is our ultimate goal.
            </p>
          </div>
        </Col>
        <Col md="5">
          <div className={Styles.secondright}>
            <h2 className={Styles.heading}>24 Hour</h2>
            <h2 className={Styles.heading}>Support</h2>
            <p className={Styles.description}>
              At Homeland Protect, our management model is more than just a
              framework – it's a commitment to fostering a culture of excellence
              and continuous improvement, ensuring that we deliver the highest
              standard of service to our clients.
            </p>
            <br />
            <h2 className={Styles.heading}>99% Satisfied</h2>
            <h2 className={Styles.heading}>Customers</h2>
            <p className={Styles.description}>
              Our management model is the backbone of our organization, uniting
              us under shared values of responsibility, ownership, and
              entrepreneurship. This model, what we call the 'Homeland Model,'
              shapes our culture and guides our actions at every level.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Section3;
