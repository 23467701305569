import React, { Fragment, useEffect } from "react";
import Header from "../../Components/Header";
import HeaderWithImage from "../../Components/HeaderWithImage";
import Footer from "../../Components/Footer";

import ConsultancyBanner from "../../Assets/Images/consultancy-banner.jpg";
import MobileBanner from "../../Assets/mobileImages/freeconsult-banner.jpg";
import ConsultancyForm from "./ConsultancyForm";

const FreeConsultancy = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);
  return (
    <Fragment>
      <Header />
      <HeaderWithImage
        breadCrumb1="Home"
        breadCrumb2="Free Consultancy"
        title="Please submit your request!"
        subtitle="Let us know how we can assist you"
        desktopBanner={ConsultancyBanner}
        mobileBanner={MobileBanner}
      />
      <ConsultancyForm />
      <Footer />
    </Fragment>
  );
};

export default FreeConsultancy;
