import React from "react";
import { Container } from "react-bootstrap";
import Styles from "../serviceInner.module.scss";

import Tick from "../../../Assets/Images/tic-green.png";
import Form from "../../../Components/Form";

const Section1 = () => {
  return (
    <Container className={Styles.section1security}>
      <h1 className={Styles.heading}>Electronic Security</h1>
      <p className={Styles.description}>
        Upgrade your security framework with our electronic security offerings,
        harnessing state-of-the-art technology to safeguard your assets and
        premises efficiently. Our integrated solutions, ranging from access
        control systems to CCTV surveillance, offer complete defense against
        unauthorized access and potential security breaches.
      </p>
      <div className={Styles.listOptions}>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            Customized Solutions: Tailor electronic security solutions to meet
            the specific needs and requirements of each client, ensuring optimal
            protection for their assets and facilities.
          </p>
        </div>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            Continuous Monitoring: Implement 24/7 monitoring of electronic
            security systems to promptly detect and respond to any security
            incidents or breaches.
          </p>
        </div>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            Regular Maintenance: Conduct regular maintenance and updates of
            electronic security equipment to ensure reliability and
            effectiveness in protecting assets and facilities.
          </p>
        </div>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            Scalability: Design electronic security systems with scalability in
            mind, allowing for easy expansion or modification as security needs
            evolve over time.
          </p>
        </div>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            User Training: Provide comprehensive training to users on how to
            operate electronic security systems effectively and respond
            appropriately to security threats or emergencies.
          </p>
        </div>
      </div>
      <div className={Styles.securityForm}>
        <h1 className={Styles.heading}>Contact Our Team</h1>
        <Form />
      </div>
    </Container>
  );
};

export default Section1;
