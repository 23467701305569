import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Styles from "./style.module.scss";

import MapImg from "../../Assets/Images/locationsMap.jpeg";
import PinIcon from "../../Assets/Images/ion_location.png";

const locations = [
  {
    id: 1,
    name: "Houston, Texas",
  },
  {
    id: 2,
    name: "Dallas, Texas",
  },
  {
    id: 3,
    name: "Austin, Texas",
  },
  {
    id: 4,
    name: "San Antonio, Texas",
  },
  {
    id: 5,
    name: "Denver, Colorado",
  },
  {
    id: 6,
    name: "Colorado Springs, Colorado",
  },
  {
    id: 7,
    name: "Miami, Florida",
  },
  {
    id: 8,
    name: "Fort Lauderdale, Florida",
  },
];

const Locations = () => {
  return (
    <Container className={Styles.locationsContainer}>
      <p className={Styles.heading}>Where You Can Find us</p>
      <Row>
        <Col md={6}>
          <div className={Styles.mapImg}>
            <img src={MapImg} alt="Location Map" />
          </div>
        </Col>
        <Col md={6}>
          <div className={Styles.mapsList}>
            <ul>
              {locations.length > 0 &&
                locations.map((data, _idx) => (
                  <li key={_idx}>
                    <div className={Styles.locationtext}>
                      <img src={PinIcon} alt={data.name + data.id} />{" "}
                      <span>{data.name}</span>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Locations;
