import React, { Fragment, useEffect } from "react";
import Header from "../../../Components/Header";
import HeaderWithImage from "../../../Components/HeaderWithImage";
import Footer from "../../../Components/Footer";

import Banner from "../../../Assets/Images/security_guard_banner.jpg";
import MobBanner from "../../../Assets/mobileImages/electronic-banner.jpg";
import Section1 from "./section1";

const SecurityGuard = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);
  return (
    <Fragment>
      <Header />
      <HeaderWithImage
        normalImage
        desktopBanner={Banner}
        mobileBanner={MobBanner}
        breadCrumb1="Home"
        breadCrumb2="Security Guarding"
        title=""
      />
      <Section1 />
      <Footer />
    </Fragment>
  );
};

export default SecurityGuard;
