import React, { Fragment } from "react";
import Header from "../../Components/Header";
import HeaderWithImage from "../../Components/HeaderWithImage";
import Footer from "../../Components/Footer";

import ServiceBanner from "../../Assets/Images/service_banner.jpg";
import MobBanner from "../../Assets/mobileImages/service_banner.jpg";
import Section1 from "./Section1";
import Section2 from "./Section2";

const Services = () => {
  return (
    <Fragment>
      <Header />
      <HeaderWithImage
        desktopBanner={ServiceBanner}
        mobileBanner={MobBanner}
        breadCrumb1="Home"
        breadCrumb2="Services"
        title=""
      />
      <Section1 />
      <Section2 />
      <Footer />
    </Fragment>
  );
};

export default Services;
