import React, { useState, useRef } from "react";
import Styles from "./style.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import Button from "../../Components/Button";
import emailjs from "@emailjs/browser";
import AlertBox from "../../Components/Alert";
import Loader from "../../Components/Loader";

const ContactForm = () => {
  const form = useRef();

  const initialValues = {
    name: "",
    email: "",
    message: "",
  };
  const [formData, setFormData] = useState(initialValues);
  const [isLoader, setIsLoader] = useState(false);
  const [isApiResult, setIsApiResult] = useState({
    show: false,
    type: 1,
    message: "",
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setIsLoader(true);
    if (
      formData.name !== "" &&
      formData.email !== "" &&
      formData.message !== ""
    ) {
      emailjs
        .sendForm(
          "service_h43q1i5",
          "template_4swail6",
          form.current,
          "dHLCEzRKfc5Pd_JI-"
        )
        .then((response) => {
          setIsLoader(false);
          setIsApiResult({
            show: true,
            type: 1,
            message: "Email sent successfully. We will contact you shortly.",
          });

          formData.name = "";
          formData.email = "";
          formData.message = "";
        })
        .catch((error) => {
          setIsLoader(false);
          setIsApiResult({
            show: true,
            type: 2,
            message: "Something went wrong. Try again later.",
          });
        });
    } else {
      setIsLoader(false);
      setIsApiResult({
        show: true,
        type: 2,
        message: "Please fill required fields.",
      });
    }
  };

  return (
    <Container className={Styles.contactFormBox}>
      <Row>
        <Col md="8">
          <div className={Styles.contactInfo}>
            <h2 className={Styles.heading}>Get In Touch With Us Today.</h2>
            <p className={Styles.description}>
              Ready to enhance your security measures or have questions about
              our services?
            </p>
            <p className={Styles.description}>We're here to help!</p>
            <p className={Styles.description}>
              Reach out to us via phone, email, or our online form, and our
              friendly team will be delighted to assist you. Your safety is our
              priority, and we're committed to providing top-notch security
              solutions just the way you need them. Contact Homeland Protect now
              and take the first step towards a safer tomorrow.
            </p>
          </div>
        </Col>
        <Col md="4">
          <div className={Styles.contactBox}>
            <p className={Styles.heading}>Leave Us Messege</p>
            <form ref={form}>
              <input
                className={Styles.inputBox}
                type="text"
                name="name"
                required
                placeholder="Name"
                value={formData.name}
                onChange={handleOnChange}
              />
              <br />
              <input
                className={Styles.inputBox}
                type="email"
                name="email"
                required
                placeholder="Email"
                value={formData.email}
                onChange={handleOnChange}
              />
              <br />
              <textarea
                className={Styles.textarea}
                name="message"
                placeholder="Message"
                value={formData.message}
                required
                onChange={handleOnChange}
              ></textarea>
              <br />
              <Button title="Send" onClick={handleOnSubmit} purple />
            </form>
          </div>

          <br />
          <Loader show={isLoader} />
          <AlertBox
            isShow={isApiResult.show}
            type={isApiResult.type}
            message={isApiResult.message}
          />
        </Col>
      </Row>
      <Row className={Styles.contactBottom}>
        <Col md="4">
          <div className={Styles.contactDetailBox}>
            <p className={Styles.heading}>Address</p>
            <p className={Styles.description}>
              13105 Northwest Freeway Suite # 1230 Houston, Texas 77040
            </p>
          </div>
        </Col>
        <Col md="4">
          <div className={Styles.contactDetailBox}>
            <p className={Styles.heading}>Email</p>
            <p className={Styles.description}>info@homelandprotect.com</p>
          </div>
        </Col>
        <Col md="4">
          <div className={Styles.contactDetailBox}>
            <p className={Styles.heading}>Phone</p>
            <p className={Styles.description}>713-690-7866</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactForm;
