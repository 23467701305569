import React from "react";
import Styles from "./style.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from "../../Components/Button";
import getWindowDimensions from "../../Utils/screenSize";

// Images
import UserImage1 from "../../Assets/Images/user_img1.png";
import UserImage2 from "../../Assets/Images/user_img2.png";
import UserImage3 from "../../Assets/Images/user_img3.png";
import UserImage4 from "../../Assets/Images/user_img4.png";
import UserImage5 from "../../Assets/Images/user_img5.png";
import UserImage6 from "../../Assets/Images/user_img6.png";
import UserImage7 from "../../Assets/Images/user_img7.png";
import UserImage8 from "../../Assets/Images/user_img8.png";

import BlogImage1 from "../../Assets/Images/blog_img1.png";
import BlogImage2 from "../../Assets/Images/blog_img2.png";
import BlogImage3 from "../../Assets/Images/blog_img3.png";
import BlogImage4 from "../../Assets/Images/blog_img4.png";
import BlogImage5 from "../../Assets/Images/blog_img5.png";
import BlogImage6 from "../../Assets/Images/blog_img6.png";

const shortBlogList = [
  {
    id: 1,
    userImg: UserImage1,
    name: "Jack John",
    date: "12-02-2024",
    heading: "Highly Trained Personnel:",
    description:
      "Security personnel should be extensively trained in various aspects of VIP protection, including threat assessment, defensive driving, first aid, and crisis management.",
    link: "/blogdetail",
  },
  {
    id: 2,
    userImg: UserImage2,
    name: "John Doe",
    date: "12-03-2024",
    heading: "With a proven track",
    description:
      "With a proven track record of excellence and a team of dedicated professionals, we prioritize your safety",
    link: "/blogdetail",
  },
];

const desktopLongBlogList = [
  {
    id: 1,
    blogLink: "/blogdetail",
    image: BlogImage1,
    title: "The Evolution of Homeland Security",
    description:
      "Since its inception, the landscape of homeland security has undergone significant transformations. adapting to emerging.",
    userImg: UserImage3,
    username: "Jotham willan",
    postdate: "01-Jan-2024",
  },
  {
    id: 2,
    blogLink: "/blogdetail",
    image: BlogImage2,
    title: "The Importance of Home Security",
    description:
      "One of the key reasons every household needs a comprehensive security system is deterrence. Research consistently",
    userImg: UserImage4,
    username: "Alice john",
    postdate: "02-Jan-2024",
  },
  {
    id: 3,
    blogLink: "/blogdetail",
    image: BlogImage3,
    title: "Predictive Analytics and Beyond",
    description:
      "The integration of artificial intelligence into security systems represents a paradigm shift in how we perceive and manage security.",
    userImg: UserImage5,
    username: "Rotham alan",
    postdate: "03-Jan-2024",
  },
  {
    id: 4,
    blogLink: "/blogdetail",
    image: BlogImage4,
    title: "The Legalities of Armed Security",
    description:
      "Navigating the legal landscape of armed security requires a thorough understanding of regulations, licensing requirements.",
    userImg: UserImage6,
    username: "Elizbeth",
    postdate: "04-Jan-2024",
  },
  {
    id: 5,
    blogLink: "/blogdetail",
    image: BlogImage5,
    title: "The Evolution of Homeland Security: From Past to Present",
    description:
      "Since its inception, the landscape of homeland security has undergone significant transformations.",
    userImg: UserImage7,
    username: "Jack John",
    postdate: "05-Jan-2024",
  },
  {
    id: 6,
    blogLink: "/blogdetail",
    image: BlogImage6,
    title: "The Importance of Home Security",
    description:
      "One of the key reasons every household needs a comprehensive security system is deterrence. Research consistently",
    userImg: UserImage8,
    username: "Jack John",
    postdate: "06-Jan-2024",
  },
];

const mobileLongBlogList = [
  {
    id: 1,
    blogLink: "/blogdetail",
    image: BlogImage1,
    title: "The Evolution of Homeland Security",
    description:
      "Since its inception, the landscape of homeland security has undergone significant transformations. adapting to emerging.",
    userImg: UserImage3,
    username: "Jotham willan",
    postdate: "01-Jan-2024",
  },
  {
    id: 2,
    blogLink: "/blogdetail",
    image: BlogImage2,
    title: "The Importance of Home Security",
    description:
      "One of the key reasons every household needs a comprehensive security system is deterrence. Research consistently",
    userImg: UserImage4,
    username: "Alice john",
    postdate: "02-Jan-2024",
  },
  {
    id: 3,
    blogLink: "/blogdetail",
    image: BlogImage3,
    title: "Predictive Analytics and Beyond",
    description:
      "The integration of artificial intelligence into security systems represents a paradigm shift in how we perceive and manage security.",
    userImg: UserImage5,
    username: "Rotham alan",
    postdate: "03-Jan-2024",
  },
];

const Blogs = () => {
  const { width } = getWindowDimensions();
  return (
    <Container className={Styles.blogsContainer}>
      <div className={Styles.blogsHeader}>
        <p className={Styles.heading}>Our Blogs</p>
        {/* <Link to="#" className={Styles.viewall}>
          View All
        </Link> */}
      </div>
      <Row>
        <Col md="3">
          <div className={Styles.blogAds}>
            {shortBlogList.length > 0 &&
              shortBlogList.map((data, _idx) => (
                <div className={Styles.blogAdDetail} key={_idx}>
                  <p className={Styles.heading}>{data.heading}</p>
                  {/* <div className={Styles.userDetail}>
                    <img src={data.userImg} alt="user icon" />
                    <div className={Styles.userInfo}>
                      <p className={Styles.postname}>{data.name}</p>
                      <p className={Styles.postdate}>{data.date}</p>
                    </div>
                  </div> */}
                  <p className={Styles.postdescription}>{data.description}</p>
                  <Button
                    link={data.link}
                    title="Read more"
                    state={{ blogId: data.id, blogTitle: data.title }}
                  />
                </div>
              ))}
          </div>
        </Col>
        <Col md="9">
          <Row className="align-items-center">
            {width > 1024
              ? desktopLongBlogList.length > 0 &&
                desktopLongBlogList.map((data, _idx) => (
                  <Col md="4">
                    <div className={Styles.longBlog} key={_idx}>
                      <Link
                        to={data.blogLink}
                        state={{ blogId: data.id, blogTitle: data.title }}
                      >
                        <img
                          className={Styles.blogImage}
                          src={data.image}
                          alt={data.title}
                        />
                      </Link>
                      <p className={Styles.title}>{data.title}</p>
                      <p className={Styles.description}>{data.description}</p>
                      {/* <div className={Styles.userInfo}>
                        <img src={data.userImg} alt={data.username} />
                        <span className={Styles.username}>
                          {data.username} .{" "}
                        </span>
                        <span className={Styles.userdate}>{data.postdate}</span>
                      </div> */}
                    </div>
                    <br />
                  </Col>
                ))
              : mobileLongBlogList.length > 0 &&
                mobileLongBlogList.map((data, _idx) => (
                  <Col md="4">
                    <div className={Styles.longBlog} key={_idx}>
                      <Link
                        to={data.blogLink}
                        state={{ blogId: data.id, blogTitle: data.title }}
                      >
                        <img
                          className={Styles.blogImage}
                          src={data.image}
                          alt={data.title}
                        />
                      </Link>
                      <p className={Styles.title}>{data.title}</p>
                      <p className={Styles.description}>{data.description}</p>
                      {/* <div className={Styles.userInfo}>
                        <img src={data.userImg} alt={data.username} />
                        <span className={Styles.username}>
                          {data.username} .{" "}
                        </span>
                        <span className={Styles.userdate}>{data.postdate}</span>
                      </div> */}
                    </div>
                  </Col>
                ))}
          </Row>
        </Col>
      </Row>
      <div className={Styles.loadmore}>
        <Button link="/blogs" title="Read more" />
      </div>
    </Container>
  );
};

export default Blogs;
