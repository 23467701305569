import React, { Fragment } from "react";
import Header from "../../Components/Header";
import Slider from "../../Components/Slider";
import Services from "./services";
import Locations from "./locations";
import Mission from "./mission";
import OurBlogs from "./blogs";
import Industries from "./industries";
import OurTeam from "./team";
import Footer from "../../Components/Footer";

// Images
import service1 from "../../Assets/Images/service1.png";
import service2 from "../../Assets/Images/service2.png";
import service3 from "../../Assets/Images/service3.png";
import service4 from "../../Assets/Images/service4.png";

const servicesList = [
  {
    image: service1,
    title: "24/7 Protection",
    description:
      "Our team is here for you day and night, ensuring your safety around the clock",
  },
  {
    image: service2,
    title: "Trusted Guards",
    description:
      "Choose from our armed or unarmed security personnel, trained to keep you secure.",
  },
  {
    image: service3,
    title: "On-Demand Surveillance",
    description:
      "Rent our security camera trailers for flexible monitoring wherever you need it",
  },
  {
    image: service4,
    title: "Enhanced Patrols",
    description:
      "Experience top-notch security with our comprehensive patrol wand features.",
  },
];

function Home() {
  return (
    <Fragment>
      <Header />
      <Slider />
      <Services services={servicesList} />
      <Locations />
      <Mission />
      <OurBlogs />
      <Industries />
      <OurTeam />
      <Footer />
    </Fragment>
  );
}

export default Home;
