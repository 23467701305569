import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Styles from "./style.module.scss";

const Section2 = () => {
  return (
    <Container className={Styles.section2}>
      <Row className="align-items-center">
        <Col md="6">
          <div className={Styles.left}>
            <h2 className={Styles.heading}>Our Management Model</h2>
          </div>
        </Col>
        <Col md="6">
          <div className={Styles.right}>
            <p className={Styles.title}>
              Fostering a Culture of Responsibility and Leadership
            </p>
            <p className={Styles.description}>
              {" "}
              At Homeland Protect, our management model is more than just a
              framework – it's a commitment to fostering a culture of excellence
              and continuous improvement, ensuring that we deliver the highest
              standard of service to our clients.
            </p>
            <p className={Styles.description}>
              Our management model is the backbone of our organization, uniting
              us under shared values of responsibility, ownership, and
              entrepreneurship. This model, what we call the 'Homeland Model,'
              shapes our culture and guides our actions at every level.
            </p>
          </div>
        </Col>
      </Row>
      <Row className="align-items-center" style={{ marginTop: "25px" }}>
        <Col md="6">
          <div className={Styles.left}>
            <h2 className={Styles.heading}>Our Strategy</h2>
          </div>
        </Col>
        <Col md="6">
          <div className={Styles.right}>
            <p className={Styles.title}>
               Navigating the Changing Tides of Security.
            </p>
            <p className={Styles.description}>
              In a world marked by constant change and evolving threats,
              Homeland Protect remains steadfast in its commitment to protecting
              what truly matters. From the safety of individuals and their
              belongings to the ongoing progress of society and the success of
              our valued clients, our strategy is clear: Adaptability and
              Vigilance.
            </p>
            <p className={Styles.description}>
              In an era defined by geopolitical shifts and technological
              advancements, we remain agile, proactive, and ready to address a
              multitude of security challenges. With our finger on the pulse of
              emerging threats, we're dedicated to staying one step ahead,
              ensuring your safety and security remain our top priorities.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Section2;
