import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Styles from "./style.module.scss";
// Images
import Team from "../../Assets/Images/team.png";
import Team1 from "../../Assets/Images/team1.png";
import Team2 from "../../Assets/Images/team2.png";
import Team3 from "../../Assets/Images/team3.png";
import Team4 from "../../Assets/Images/team4.png";

const team = [
  {
    image: Team1,
    name: "Ema Lodhi",
    position: "Director of Human resources",
  },
  {
    image: Team2,
    name: "Shelly Llavore",
    position: "VP Client Relation",
  },
  {
    image: Team3,
    name: "Muhummad Lodhi ",
    position: "CFO",
  },
  {
    image: Team4,
    name: "Saroosh Lodhi",
    position: "Operation Manager",
  },
];

const OurTeam = () => {
  return (
    <Container className={Styles.teamContainer}>
      {/* <p className={Styles.heading}>Our Team</p>
      <Row>
        {team.length > 0 &&
          team.map((data, _idx) => (
            <Col md="3">
              <div className={Styles.teamBox}>
                <img
                  className={Styles.image}
                  src={data.image}
                  alt={data.name}
                />
                <p className={Styles.name}>{data.name}</p>
                <p className={Styles.position}>{data.position}</p>
              </div>
            </Col>
          ))}
      </Row> */}
      <div className={Styles.teamDetails}>
        <Row className="align-items-center">
          <Col md="6">
            <img className={Styles.teamImage} src={Team} alt="Team" />
          </Col>
          <Col md="6">
            <div className={Styles.teamInnerDetails}>
              <p className={Styles.heading}>We care about your opinion</p>
              <p className={Styles.description}>
                At Homeland Protect, your voice matters. We prioritize your
                opinion because we believe in the power of diverse perspectives
                to drive meaningful change. Join our community dedicated to
                making the world a safer place, where your insights and ideas
                are valued and respected. Together, we'll protect lives and
                assets while fostering innovation and growth. Explore our
                openings and become an integral part of our passionate team
                today.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default OurTeam;
