import React from "react";
import { Container } from "react-bootstrap";
import Styles from "../serviceInner.module.scss";

import Tick from "../../../Assets/Images/tic-green.png";
import Form from "../../../Components/Form";

const Section1 = () => {
  return (
    <Container className={Styles.section1security}>
      <h1 className={Styles.heading}>Security Guarding</h1>
      <p className={Styles.description}>
        Count on our vigilant security guards to stand watch, ensuring your
        safety with unwavering dedication and expertise. Our trained
        professionals are equipped to handle any situation, providing a visible
        impediment to potential threats and offering proactive protection for
        your peace of mind.
      </p>

      <div className={Styles.listOptions}>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            24/7 Coverage: Offer around-the-clock security coverage to ensure
            constant protection for clients and their assets.
          </p>
        </div>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            Customized Solutions: Provide tailored security solutions based on
            the specific needs and requirements of each client, whether it's a
            residential, commercial, or industrial property.
          </p>
        </div>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            Emergency Response: Develop a robust emergency response plan and
            ensure that our security guards are well-trained to handle
            emergencies effectively.
          </p>
        </div>
        <div className={Styles.listMain}>
          <img className={Styles.icon} src={Tick} alt="Icon" />
          <p className={Styles.listPoint}>
            Regular Patrols: Implement regular patrols and checks of the
            premises to deter potential threats and maintain a visible security
            presence.
          </p>
        </div>
      </div>
      <div className={Styles.securityForm}>
        <h1 className={Styles.heading}>Contact Our Team</h1>
        <Form />
      </div>
    </Container>
  );
};

export default Section1;
